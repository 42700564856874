import {
  createRouter,
  createWebHistory,
  type RouteLocationNormalized,
  type RouteMeta
} from 'vue-router'
import { createNabooAdminRoutes } from '@/router/routes'
import type { App } from 'vue'

export interface CustomRouteMeta extends RouteMeta {
  assertQueries: (route: RouteLocationNormalized) => boolean
}


const nabooAdminRouter = (app: App) => {
  const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: createNabooAdminRoutes(app)
  })

  return router
}
export default nabooAdminRouter
